// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-drive-js": () => import("./../../../src/pages/drive.js" /* webpackChunkName: "component---src-pages-drive-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-grouptrips-js": () => import("./../../../src/pages/grouptrips.js" /* webpackChunkName: "component---src-pages-grouptrips-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privatetrips-js": () => import("./../../../src/pages/privatetrips.js" /* webpackChunkName: "component---src-pages-privatetrips-js" */),
  "component---src-pages-stay-private-vineyard-js": () => import("./../../../src/pages/stay-private-vineyard.js" /* webpackChunkName: "component---src-pages-stay-private-vineyard-js" */),
  "component---src-pages-stay-resort-vineyard-js": () => import("./../../../src/pages/stay-resort-vineyard.js" /* webpackChunkName: "component---src-pages-stay-resort-vineyard-js" */),
  "component---src-pages-thank-you-pt-js": () => import("./../../../src/pages/thank-you-pt.js" /* webpackChunkName: "component---src-pages-thank-you-pt-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-transportation-js": () => import("./../../../src/pages/transportation.js" /* webpackChunkName: "component---src-pages-transportation-js" */),
  "component---src-pages-winemakers-js": () => import("./../../../src/pages/winemakers.js" /* webpackChunkName: "component---src-pages-winemakers-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */)
}

