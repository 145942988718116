import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const theme = createTheme({
    palette: {
        primary: {
            main: "#006837",
        },
        myorange: {
            main: `#F45A28`,
        },
        secondary: {
            main: `#CCCCCC`,
        },
        error: {
            main: red.A400,
        },
        background: {
            default: `#fff`,
        },
    },
});

export default theme;
